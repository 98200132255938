// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-husky-src-pages-404-tsx": () => import("./../../../../gatsby-theme-husky/src/pages/404.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-pages-404-tsx" */),
  "component---gatsby-theme-husky-src-templates-academy-academy-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/Academy/Academy.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-academy-academy-tsx" */),
  "component---gatsby-theme-husky-src-templates-article-article-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/Article/Article.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-article-article-tsx" */),
  "component---gatsby-theme-husky-src-templates-article-list-article-list-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/ArticleList/ArticleList.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-article-list-article-list-tsx" */),
  "component---gatsby-theme-husky-src-templates-contact-contact-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/Contact/Contact.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-contact-contact-tsx" */),
  "component---gatsby-theme-husky-src-templates-frame-frame-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/Frame/Frame.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-frame-frame-tsx" */),
  "component---gatsby-theme-husky-src-templates-home-page-home-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/HomePage/HomePage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-home-page-home-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-lds-page-lds-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/LdsPage/LdsPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-lds-page-lds-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-product-list-product-list-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/ProductList/ProductList.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-product-list-product-list-tsx" */),
  "component---gatsby-theme-husky-src-templates-product-page-product-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-product-page-product-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-recipe-list-recipe-list-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/RecipeList/RecipeList.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-recipe-list-recipe-list-tsx" */),
  "component---gatsby-theme-husky-src-templates-recipe-recipe-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/Recipe/Recipe.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-recipe-recipe-tsx" */),
  "component---gatsby-theme-husky-src-templates-sitemap-page-sitemap-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/SitemapPage/SitemapPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-sitemap-page-sitemap-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-store-list-store-list-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/StoreList/StoreList.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-store-list-store-list-tsx" */)
}

